import { ChevronDownIcon, Cross2Icon } from "@radix-ui/react-icons";
import { Box, Checkbox, Popover } from "@radix-ui/themes";
import { Command } from "cmdk";
import React, { forwardRef, useEffect, useId, useState, useRef } from "react";
import { useFieldArray, useFormContext } from "remix-validated-form";
import { Badge } from "../Badge/Badge";
import { Button } from "../Button/Button";
import { Flex } from "../Flex/Flex";
import { ScrollArea } from "../ScrollArea/ScrollArea";
import { Separator } from "../Separator/Separator";
import { Text } from "../Text/Text";
import { TextInput } from "../TextInput/TextInput";
import "./MultiSelect.css";

type MultiSelectProps = {
  label?: string;
  hint?: string;
  name?: string;
  placeholder?: string;
  shouldFilter?: boolean;
  defaultValue?: string[];
  options: { label: string; value: string }[];
  displayType?: "number" | "badge";
  selectedValues: { label: string; value: string }[];
  onChange?: (value: string[]) => void;
  onSearchChange?: (value: string) => void;
  onOptionToggle?: (value: string) => void;
  onClear?: () => void;
  onClearValue?: (value: string) => void;
  leftIcon?: React.ReactNode;
  clearLabel?: string;
};

export const MultiSelect = forwardRef<HTMLButtonElement, MultiSelectProps>(
  (
    {
      options,
      placeholder,
      shouldFilter = true,
      selectedValues,
      onSearchChange,
      onOptionToggle,
      onClear,
      leftIcon,
      displayType = "number",
      onClearValue,
      clearLabel = "Clear",
    },
    ref,
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null); // Reference for the input element
    const [maxWidth, setMaxWidth] = useState<string>("0px");

    useEffect(() => {
      if (!!containerRef) {
        setMaxWidth(`${containerRef?.current?.offsetWidth || 0}px`);
      }
    }, [containerRef, window.innerWidth]);

    useEffect(() => {
      const container = scrollRef?.current;
      if (container) {
        container.scrollLeft = container.scrollWidth;
      }
    }, [scrollRef?.current?.scrollWidth]);

    const toggleOption = (value: string) => {
      onOptionToggle?.(value);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    const handleClear = () => {
      onClear?.();
    };

    const handleOnClearValue = (value: string) => {
      onClearValue?.(value);
    };

    const handleInputFocus = (event: React.MouseEvent) => {
      // Focus the input only when it's explicitly clicked
      event.stopPropagation();
      inputRef.current?.focus();
    };

    return (
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger>
          <Button
            ref={ref}
            className="Trigger"
            variant="surface"
            leftIcon={leftIcon}
            rightIcon={
              <Flex
                height="100%"
                align="center"
                justify="center"
                pl="3"
                style={{ borderLeft: "1px solid var(--gray-a6)" }}
              >
                <ChevronDownIcon />
              </Flex>
            }
            onClick={handleTogglePopover}
            onMouseDown={(e) => e.preventDefault()} // Prevents the button from focusing
          >
            <Flex
              ref={containerRef}
              width="100%"
              maxWidth="100%"
              align="center"
              justify={"start"}
              gap="1"
              overflow="hidden"
            >
              {displayType === "number" && (
                <>
                  <Text size="2" color="gray" className="ComboboxPlaceholder">
                    {placeholder}
                  </Text>
                  <Text size="2" color="green" weight="bold">
                    {selectedValues.length}
                  </Text>
                </>
              )}
              {displayType === "badge" && (
                <Flex ref={scrollRef} width="100%" maxWidth={maxWidth} align="center" overflow="auto">
                  {selectedValues.length > 0 &&
                    selectedValues?.map((value) => {
                      return (
                        <Badge key={value.label} variant="solid" radius="full">
                          {value.label}
                          <Flex
                            style={{ cursor: "var(--cursor-button)" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOnClearValue(value.value);
                            }}
                          >
                            <Cross2Icon />
                          </Flex>
                        </Badge>
                      );
                    })}
                  {selectedValues.length === 0 && (
                    <Text size="2" color="gray" className="ComboboxPlaceholder">
                      {placeholder}
                    </Text>
                  )}
                </Flex>
              )}
            </Flex>
          </Button>
        </Popover.Trigger>
        <Popover.Content style={{ width: 200, padding: 0, overflowX: "hidden" }} align="start">
          <Command loop shouldFilter={shouldFilter} className="Command">
            <Command.Input asChild>
              <Box p="1">
                <TextInput
                  ref={inputRef}
                  placeholder="Search..."
                  className="CommandInput"
                  onClick={handleInputFocus} // Focus on click
                  onChange={(e) => onSearchChange?.(e.target.value)}
                />
              </Box>
            </Command.Input>
            <Separator />
            <Command.List asChild>
              <Command.Empty>
                <Box p="1">
                  <Text size="1" color="gray">
                    No results
                  </Text>
                </Box>
              </Command.Empty>
              <Command.Group style={{ maxHeight: 300, overflowY: "auto" }}>
                <Flex gap="1" direction="column" p="1">
                  {options.map((option) => {
                    const isSelected = selectedValues.findIndex((v) => v.value === option.value) !== -1;
                    return (
                      <Command.Item className="CommandItem" key={option.value} asChild>
                        <Box px="2" py="1">
                          {/* Wrapping the label and checkbox together so when the user clicks the label the checkbox get checked. */}
                          <label
                            style={{ display: "flex", alignItems: "center", gap: "8px", cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              toggleOption(option.value); // Toggle the checkbox when clicked
                            }}
                          >
                            <Checkbox checked={isSelected} value={option.value} />
                            <Text as="span" size="2">
                              {option.label}
                            </Text>
                          </label>
                        </Box>
                      </Command.Item>
                    );
                  })}
                </Flex>
              </Command.Group>
              <Separator />
              <Command.Group>
                <Flex p="1" gap="1">
                  {selectedValues.length > 0 ? (
                    <>
                      <Command.Item
                        className="CommandItem"
                        onSelect={() => handleClear()}
                        style={{
                          pointerEvents: "auto",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Text size="2" color="gray">
                          {clearLabel}
                        </Text>
                      </Command.Item>
                      <Command.Separator data-accent-color="gray" className="CommandSeparator" />
                    </>
                  ) : null}
                  <Command.Item
                    className="CommandItem"
                    onSelect={() => setIsPopoverOpen(false)}
                    style={{
                      pointerEvents: "auto",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Text size="2" color="gray">
                      Close
                    </Text>
                  </Command.Item>
                </Flex>
              </Command.Group>
            </Command.List>
          </Command>
        </Popover.Content>
      </Popover.Root>
    );
  },
);

export const FormMultiSelect = ({ ...props }: MultiSelectProps) => {
  const randId = useId();
  const fieldName = props.name || randId;
  const formId = props.name ? undefined : `${randId}-form`;
  const [selectedValues, helpers, error] = useFieldArray(fieldName, {
    formId,
  });
  const { isSubmitting } = useFormContext(formId);

  useEffect(() => {
    if (props.defaultValue && !isSubmitting) {
      for (const value of props.defaultValue) {
        if (selectedValues.findIndex((v) => v.defaultValue === value) === -1) {
          helpers.push(value);
        }
      }
    }
  }, [props.defaultValue]);

  const toggleOption = (value: string) => {
    const index = selectedValues.findIndex((v) => v.defaultValue === value);
    if (index !== -1) {
      helpers.remove(index);
    } else {
      helpers.push(value);
    }
  };

  const handleClear = () => {
    for (let i = 0; i < selectedValues.length; i++) {
      helpers.pop();
    }
  };

  const component = (
    <MultiSelect
      {...props}
      selectedValues={selectedValues.map((v) => ({
        label: v.key,
        value: v.defaultValue,
      }))}
      onOptionToggle={toggleOption}
      onClear={handleClear}
    />
  );

  if (props.label) {
    return (
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {props.label}
        </Text>
        {component}
        {props.hint && !error ? (
          <Text as="div" size="1" color="gray" mt="1">
            {props.hint}
          </Text>
        ) : null}
        {error ? (
          <Text as="div" size="1" color="red" mt="1">
            {error}
          </Text>
        ) : null}
      </label>
    );
  }
  return component;
};
