import {
  HoverCard as RadixHoverCard,
  HoverCardContent as RadixHoverCardContent,
  HoverCardContentProps as RadixHoverCardContentProps,
  HoverCardProps as RadixHoverCardProps,
  HoverCardTrigger as RadixHoverCardTrigger,
  HoverCardTriggerProps as RadixHoverCardTriggerProps,
} from "@radix-ui/react-hover-card";
import React, { forwardRef } from "react";

// Define a root component for the HoverCard, wrapping RadixHoverCard.Root
type HoverCardRootProps = RadixHoverCardProps;
const HoverCardRoot = ({ ...props }: HoverCardRootProps) => {
  return <RadixHoverCard {...props} />;
};

// Define the trigger component, forwarding the ref
const HoverCardTrigger = forwardRef<HTMLAnchorElement, RadixHoverCardTriggerProps>(({ ...props }, ref) => {
  return <RadixHoverCardTrigger {...props} ref={ref} />;
});

// Extend HoverCardContentProps to include backgroundColor, boxShadow, and padding
interface ExtendedHoverCardContentProps extends RadixHoverCardContentProps {
  backgroundColor?: string;
  boxShadow?: string;
  padding?: string;
}

// Define the content component, forwarding the ref and accepting custom styles
const HoverCardContent = forwardRef<HTMLDivElement, ExtendedHoverCardContentProps>(
  (
    { backgroundColor = "white", boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.1)", padding = "16px", style, ...props },
    ref,
  ) => {
    return (
      <RadixHoverCardContent
        {...props}
        ref={ref}
        style={{ backgroundColor, boxShadow, padding, ...style }} // Merge padding with other custom styles
      />
    );
  },
);

// Exporting components with aliases for a consistent API
export { HoverCardRoot as Root, HoverCardTrigger as Trigger, HoverCardContent as Content };
